(function ($, Drupal) {
  'use strict';
  /**
   * Trigger generic slick carousels
   */

  var basicCarouselV1 = {
    formatter: $('.basic-carousel-v1'),
    transitionSpeed: 300,
    accessibility: true,
    defaultAutoplaySpeed: 6000,
    swipeToSlide: !0
  };

  Drupal.behaviors.basicCarouselV1 = {
    get: function (key) {
      return basicCarouselV1[key];
    },

    set: function (key, val) {
      basicCarouselV1[key] = val;
    },

    attach: function (context, settings) {
    // console.log('carousel called with context', context);

      var self = this;
      // var formatter = $(self.get('formatter');
      var formatter = $(self.get('formatter'), context);

      // console.log(formatter);

      formatter.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('> ul');
        var slide = '.carousel-slide';
        var autoplaySpeed = parseInt(carousel.attr('data-slides-speed'));

        if (isNaN(autoplaySpeed)) {
          autoplaySpeed = self.get('defaultAutoplaySpeed');
        }
        var speed = parseInt(carousel.attr('data-slides-speedplay')) || 0;

        if (speed === 0) {
          speed = self.get('transitionSpeed');
        }
        var swipeToSlide = self.get('swipeToSlide');
        var autoplay = carousel.attr('data-slides-autoplay');

        if (carousel.hasClass('carousel_loaded')) {
          return;
        }

        if (typeof autoplay == 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }

        // if "See All" button exists, move arrows
        var appendArrows = carousel;
        var controls = thisFormatter.find('.carousel-controls');

        if (controls.length > 0) {
          appendArrows = controls;
        }
        // slides to show
        var slidesToShow = parseInt(carousel.attr('data-slides-show'));
        var largeBreakpoint = {};

        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          largeBreakpoint = {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          };
        }

        // slides to scroll
        var slidesToScroll = parseInt(carousel.attr('data-slides-scroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        // carousel title (next slide)
        var slides = thisFormatter.find('li' + slide);
        var numSlides = slides.length;
        var lastSlide = numSlides - 1;
        var slideTitle = '> .carousel-slide__title';
        // Store the first slide's title separately for use on the last slide
        var firstSlideTitle = carousel.find(slides[0]).find(slideTitle).html();
        // Store the other titles for later use
        var nextSlideTitles = [];

        slides.each(function (index) {
          nextSlideTitles[index - 1] = [$(this).find(slideTitle).html()];
        });
        // Apply the appropriate titles per slide
        slides.each(function (index) {
        // The last slide get's the first slide's title
          if (index == lastSlide) {
            $(this).find(slideTitle).html(firstSlideTitle);
          }
          // All other slides get the next slide's title
          else if (index != lastSlide) {
            $(this).find(slideTitle).html(nextSlideTitles[index]);
          }
        });

        var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;
        // swipe/drag direction is opposite when in RTL
        // so better to just disable it until a fix is provided in the library
        var swipe = isRTL ? false : true;
        var draggable = swipe;
        // The default Slick navigation dots behaviour was overriden by this
        // function thus preventing the dots to work properly. Here we'll check
        // if the dots are not enabled to then keep using the customPaging override.
        // If the dots are set via CMS then this override will not be used.
        var customPager;

        if (!thisFormatter.hasClass('carousel--has-dots')) {
          customPager = function (slider, i) {
            if (Modernizr.mq('(min-width: 641px)') === true) {
              if (slidesToShow === 1) {
                return i + 1 + '/' + slider.slideCount;
              } else {
                return i + 1 + '/' + Math.round(slider.slideCount / slidesToShow);
              }
            } else {
              return i + 1 + '/' + slider.slideCount;
            }
          };
        }

        var slickOptions = {
          rtl: isRTL,
          swipe: swipe,
          draggable: draggable,
          appendArrows: appendArrows,
          autoplay: autoplay,
          autoplaySpeed: autoplaySpeed,
          slide: slide,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          swipeToSlide: swipeToSlide,
          speed: speed,
          accessibility: true,
          dots: true,
          customPaging: customPager,
          responsive: [
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            largeBreakpoint
          ]
        };

        if (!!carousel.data('lazyload')) {
        // progressive: "loads the visible slides on init, and then progressively loads the rest of the slides on window.load()"
        // ondemand: "loads slides on demand. When a slide becomes visible (or on the before slide callback) the load is fired"
          slickOptions.lazyLoad = carousel.data('lazyload');
        }

        carousel.slick(slickOptions);
        carousel.addClass('carousel_loaded');
      });
    }
  };
})(jQuery, Drupal);
